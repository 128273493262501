import EncryptUtility from "../../utility/encrypt-utility";
import breadcrumbComp from "../../common/breadcrumb-comp";
import AddFavourite from "../../utility/addFav";
import adminService from "../../admin/js/admin-service";
import CommonDialog from "@/olp-components/common/common-dialog.vue";
import utility from "@/shared/utility";
import FilterComp from "@/olp-components/common/filter-comp.vue";
export default {
  data() {
    return {
      actionList: [],
      mainPage: "",
      subPage: "",
      pageUrl: "",
      favouriteList: [],
      showAdd: false,
      backSlash: true,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      cardsArray: [],
      rejectComments: "",
      max100Rule: [
        (v) => !!v || "Field is required",
        (v) =>
          /^(?=.*\S).+$/.test(v) || "Please enter at least one text or number",
        (v) =>
          (v || "").length <= 100 ||
          "Max Length must be 100 characters or less",
      ],
      headersApprove: [
        {
          text: "Order Number",
          value: "ord_no",
          class: "primary customwhite--text",
          width: "15%",
        },
        {
          text: "Business",
          value: "business",
          class: "primary customwhite--text",
          width: "15%",
        },
        {
          text: "Status",
          value: "order_status",
          class: "primary customwhite--text",
          width: "25%",
        },
        {
          text: "Created Date",
          value: "ordered_date",
          class: "primary customwhite--text",
          width: "24%",
        },
        {
          text: "Created By",
          value: "ordered_by",
          class: "primary customwhite--text",
          width: "13%",
        },
        {
          text: "Actions",
          value: "actions",
          class: "primary customwhite--text",
          sortable: false,
          width: "13%",
        },
      ],
      headersSpecificData: [
        {
          text: "Part Number",
          value: "part_num",
        },
        {
          text: "Description",
          value: "part_desc",
        },
        { text: "Qty", value: "qty" },
        {
          text: "Availability",
          value: "avail_qty",
        },
      ],
      orderList: [],
      arrayData:[],
      showOrderDetails: false,
      showRejectDialog: false,
      partList: [],
      tempOrderList:[],
      uniqueBusinessList:[],
      uniqueOrderStatusList:[],
      uniqueOrderByList:[],
      orderId: "",
      totalRecords:0,
      addressObj: {
        address1: "",
        address2: "",
        city: "",
        state: "",
        pinCode: "",
        country: "",
        contactName: "",
        phone1: "",
        email: "",
      },
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  async mounted() {
    this.getDataOnLoad();
  },
  methods: {
    getDataOnLoad() {
      this.getReportData();
      this.getTableData();
    },
    async getReportData() {
      let reqObj = {
        user_id: this.userId,
        pass: 1,
        bu_key: 0,
        status_key: 0,
        sdate: "",
        edate: "",
      };
      let obj = { json: JSON.stringify(reqObj) };
      let responseData = await adminService.postOrderReportData(
        "post",
        obj,
        false
      );
      this.cardsArray = [
        {
          id: 1,
          icon: "mdi mdi-cog-outline",
          title: "New",
          data: responseData[0]?.New,
          background: "",
          iconColor: "primary",
        },
      
        {
          id: 2,
          icon: "mdi-check-circle-outline",
          title: "Approved",
          data: responseData[0]?.Approved,
          background: "",
          iconColor: "success",
        },
        {
          id: 3,
          icon: "mdi mdi-close-circle-outline",
          title: "Rejected",
          data: responseData[0]?.Rejected,
          background: "",
          iconColor: "error",
        },
      ];

    },
    async getTableData() {
      let tableObj = {
        user_id: this.userId,
        pass: 1,
        bu_key: 0,
        order_key: 0,
        status_key: 0,
        comments: "",
      };
      let obj = { json: JSON.stringify(tableObj) };
      let responseData = await adminService.postOrderListData(
        "post",
        obj,
        false
      );
      responseData?.forEach((element) => {
        element.ordered_date = utility.convertESTToLocal(element.ordered_date);
      });
      this.orderList = responseData;
      this.tempOrderList=responseData
      this.totalRecords=this.orderList?.length
      this.filterArrayData();
    },

    async getSpecificOrderDetails(order_key) {
      this.orderId = order_key;
      this.showOrderDetails = true;
      let tableObj = {
        user_id: this.userId,
        pass: 2,
        bu_key: 0,
        order_key: order_key,
        status_key: 0,
        comments: "",
      };
      let obj = { json: JSON.stringify(tableObj) };
      let responseData = await adminService.postOrderListData(
        "post",
        obj,
        false
      );
      this.partList = responseData[0]?.part_details;
      console.log(this.partList)
      this.addressObj = responseData.map((x) => ({
        address1: x.address1,
        address2: x.address2,
        city: x.city,
        state: x.state,
        pinCode: x.zip,
        country: x.country,
        contactName: `${x.first} ${x.last}`,
        phone1: x.phone1,
        email: x.email,
      }));
      console.log(this.addressObj);
    },
    async onClickApprove() {
      let tableObj = {
        user_id: this.userId,
        pass: 3,
        bu_key: 0,
        order_key: this.orderId,
        status_key: 0,
        comments: "",
      };
      let obj = { json: JSON.stringify(tableObj) };
      let responseData = await adminService.postOrderListData(
        "post",
        obj,
        true
      );
      if (responseData == "Order status updated successfully!") {
        this.getDataOnLoad();
        this.showOrderDetails = false;
      }
    },
    async onClickReject() {
      this.showRejectDialog = true;
    },
    async onClickSubmitReject() {
      if (this.$refs.rejectForm.validate()) {
        let tableObj = {
          user_id: this.userId,
          pass: 3,
          bu_key: 0,
          order_key: this.orderId,
          status_key: 0,
          comments: this.rejectComments,
        };
        let obj = { json: JSON.stringify(tableObj) };
        let responseData = await adminService.postOrderListData(
          "post",
          obj,
          true
        );
        if (responseData == "Order status updated successfully!") {
          this.getDataOnLoad();
          this.showOrderDetails = false;
          this.showRejectDialog = false;
        }
      }
    },
    cancelRejectDialog() {
      this.rejectComments = "";
      this.showRejectDialog = false;
      this.$refs.rejectForm.resetValidation();
    },
    filterArrayData() {

      this.uniqueBusinessList = [...new Map(this.orderList.map((item) => [item["business"], item.business])).values()];
      this.uniqueOrderStatusList = [...new Map(this.orderList.map((item) => [item["order_status"], item.order_status])).values()];
      this.uniqueOrderByList = [...new Map(this.orderList.map((item) => [item["ordered_by"], item.ordered_by])).values()];
      this.arrayData = [
        { id: 1, label: "Business", array: this.uniqueBusinessList },
        { id: 2, label: "Status", array: this.uniqueOrderStatusList },
        { id: 3, label: "User", array: this.uniqueOrderByList },
      ];
    },
    async filteredValues(value, type) {
      this.selectedFilterName = value;
      this.selectedFilterId = type;
      const filters = {
        0: () => this.tempOrderList,
        1: (value) => this.tempOrderList.filter((element) => element.business == value),
        2: (value) => this.tempOrderList.filter((element) => element.order_status == value),
        3: (value) => this.tempOrderList.filter((element) => element.ordered_by == value),

      };
      this.filterValueUpdate(filters[type](value));
    },
    filterValueUpdate(data) {
      this.totalRecords = data.length;
      this.orderList = data;
    },
  },

  components: {
    breadcrumbComp,
    CommonDialog,
    FilterComp
  },
};
